export default defineNuxtRouteMiddleware((_to, _from) => {
  const { user, org } = useUser()

  if (
    user.value && org.value?.id
    && (user.value?.roles?.includes('ROLE_SUPER_ADMIN')
    || user.value?.roles?.includes('ROLE_OPS_ADMIN')
    || user.value?.roles?.includes('ROLE_PRODUCT_ADMIN'))
  ) {
    return navigateTo(`/org/${org.value?.id}/home`)
  }
})
